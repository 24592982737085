import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import Header from './components/Header'
import Content from './components/Content'
import LocationData from './components/LocationData'
import AccountingSummaryData from './components/AccountingSummaryData'
import LoadButton from './components/LoadButton'
import BoxData from './components/BoxData'
import Users from './components/Users'
import User from './components/User'
import Countries from './components/Countries'
import Country from './components/Country'
import LoginForm from './components/LoginForm'
import jQuery from 'jquery';
import DataTable from 'datatables.net';

function App() {

    const datatables_l18 = {"sInfo":"_START_ bis _END_ von _TOTAL_ Einträgen","sInfoEmpty":"0 bis 0 von 0 Einträgen","sInfoFiltered":"(gefiltert von _MAX_ Einträgen)","sInfoPostFix":" ","sInfoThousands":".","sLengthMenu":"_MENU_ Einträge","sLoadingRecords":"Wird geladen...","sProcessing":"Bitte warten...","sSearch":"Suchen","sZeroRecords":"Keine Einträge vorhanden.",
         "oPaginate":{"sFirst":"Erste","sPrevious":"Zurück","sNext":"Vor","sLast":"Letzte"},
        "oAria":{"sSortAscending":": aktivieren, um Spalte aufsteigend zu sortieren","sSortDescending":": aktivieren, um Spalte absteigend zu sortieren"}};

    let table;
    let apiEndpoint = 'https://trisor123.de/api/public/';
    // let apiEndpoint = 'https://api.rrvm.de/';
    // const apiEndpoint = 'http://127.0.0.1:8000/';
    // const apiEndpoint = 'https://api.trisor.askatu-it.de/';
    const [isLoggedIn, setLoginState] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [displaySaveCallback, setDisplaySaveCallback] = useState(false)
    const [displayErrorCallback, setDisplayErrorCallback] = useState(false)
    const [userRoles, setUserRoles] = useState([])
    const [sandBoxUser, setSandBoxUser] = useState(false)
    const [currentView, setCurrentView] = useState('main')
    //const [tasks, setTasks] = useState([])
    const [locations, setLocations] = useState([])
    const [location, setLocation] = useState(null)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState([])
    const [accountingLocationSummary, setAccountingLocationSummary] = useState([])
    const [accountingSummary, setAccountingSummary] = useState(null)
    const [accountingSummaryItems, setAccountingSummaryItems] = useState([])
    const [box, setBox] = useState(null)
    const [boxAccounting, setBoxAccounting] = useState([])


    useEffect(() => {
        setContent(currentView);
    }, [currentView]);

    useEffect(() => {
        checkLogin();
    }, [])

    const checkLogin = async () => {

        let login = localStorage.getItem('currentUser');

        if(typeof login !== 'undefined') {
            let loginData = JSON.parse(login);
            if(loginData !== null && typeof loginData.token !== 'undefined') {

                let xhr = new XMLHttpRequest();

                xhr.onreadystatechange = function() {
                    /** make token check call */
                    if (this.readyState === 4 && this.status === 200) {
                        setLoginState(true)
                        setUserRoles(loginData.roles)
                        setSandBoxUser(loginData.sandBoxUser)
                        if(loginData.sandBoxUser)
                            apiEndpoint = 'https://trisor123.de/api-sandbox/public/'
                            // apiEndpoint = 'https://sandbox.rrvm.de/'
                    }
                };

                xhr.open("GET", apiEndpoint + "lists", true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + loginData.token );
                xhr.send();
            }
        }

    }

    const setContent = (view) => {

        let login = localStorage.getItem('currentUser');
        if(typeof login !== 'undefined') {
            let loginData = JSON.parse(login);

            if (loginData !== null && typeof loginData.token !== 'undefined') {

                if(loginData.sandBoxUser)
                    apiEndpoint = 'https://trisor123.de/api-sandbox/public/'
                    // apiEndpoint = 'https://sandbox.rrvm.de/'

                let xhr = new XMLHttpRequest();

                switch (view) {
                    case 'main':

                        xhr.onreadystatechange = function () {
                            /** make token check call */
                            if (this.readyState === 4 && this.status === 200) {

                                let myArr = JSON.parse(this.responseText);
                                let locations = [];
                                for (let i = 0; i < myArr.length; i++) {

                                    let newLocation = {
                                        id: myArr[i].id,
                                        title: myArr[i].title,
                                        street: myArr[i].street,
                                        postcode: myArr[i].postcode,
                                        city: myArr[i].city,
                                        locationAddress: myArr[i].locationAddress,
                                        countryName: myArr[i].countryName,
                                        additional_insurance_amount: parseFloat(myArr[i].additional_insurance_amount),
                                        monthly_premium_amount: parseFloat(myArr[i].monthly_premium_amount),
                                        taxRate: parseFloat(myArr[i].tax_rate),
                                        monthly_premium_amount_excl_tax: parseFloat(myArr[i].monthly_premium_amount),
                                    };

                                    locations.push(newLocation);

                                }
                                setLocations(locations)

                                if (!jQuery.fn.dataTable.isDataTable('#locations'))
                                    table = jQuery('#locations').DataTable({
                                        language: datatables_l18,
                                        columnDefs: [{ className: "text-right", "targets": [3,4,5,6,7] },
                                            { className: "text-center", "targets": [2] },
                                            { orderable: false, "targets": [1,2,3,4,5,6,7] },
                                            {
                                                'targets': [7],
                                                createdCell: (td, cellData, rowData, row, col) => {

                                                    cellData.monthlyPremiumAmount = parseFloat(cellData.monthlyPremiumAmount)
                                                    cellData.monthlyPremiumAmountExclusiveTax = parseFloat(cellData.monthlyPremiumAmount)
                                                    cellData.taxRate = parseFloat(cellData.taxRate)

                                                    ReactDOM.render(<button type="button" className="btn btn-sm "
                                                                            onClick={() => {
                                                                                setCurrentView('location');
                                                                                setLocation(cellData);
                                                                            } } >Übersicht</button>, td);

                                                }
                                            }
                                        ],
                                        processing: true,
                                        serverSide: true,
                                        ajax: {

                                            url: apiEndpoint + "lists/" ,
                                            dataSrc: "accounting",
                                            //dataType: "json",
                                            type: "GET",
                                            pageLength: 10,
                                            crossDomain: true,
                                            beforeSend: function (xhr) {
                                                xhr.setRequestHeader("Authorization",
                                                    "Bearer " + loginData.token)
                                            },

                                            data: function ( d ) {
                                                
                                                if(jQuery('.custom-datatables-filter').length > 0) {
                                                    jQuery('.custom-datatables-filter').each(function() {
                                                        d[jQuery(this).attr('id')] = jQuery(this).val();
                                
                                                    });
                                                }

                                                d.dataType = 'datatables';
                                            },
                                            dataFilter: function(data){

                                                var json = JSON.parse( data );

                                                document.getElementById('premium_amount').innerHTML = json.sumAnalytic.monthly_premium_amount_display
                                                document.getElementById('premium_amount_netto').innerHTML = json.sumAnalytic.monthly_premium_amount_display_netto
                                                // document.getElementById('insurance_amount').innerHTML = json.sumAnalytic.additional_insurance_amount_display

                
                                                return JSON.stringify( json ); // return JSON string
                                            },

                                            // ReactDOM.findDOMNode(component_name)

                                        },
                                        fnInitComplete: function(settings, json) {

                                            let startSelect = document.getElementById('period_start');
                                            let endSelect = document.getElementById('period_end');
                                            let yearSelect = document.getElementById('year_select');

                                            let start = json.sumAnalytic.min_range.split('-')
                                            let end = json.sumAnalytic.max_range.split('-')

                                            let startYear = start[0]

                                            let currentDate = new Date()
                                            start[0] = currentDate.getFullYear() - 1
                                            if(currentDate.getMonth >= 5)
                                                end[0] = currentDate.getFullYear() + 1

                                            for(let i = parseInt(startYear); i < parseInt(end[0]); i++) {

                                                let opt = document.createElement('option');
                                                opt.value = i.toString();
                                                opt.innerHTML = i.toString();
                                                if(i === parseInt(start[0]))
                                                    opt.selected = true

                                                yearSelect.appendChild(opt);
                                            }

                                            yearSelect.addEventListener('change', function() {

                                                while (startSelect.options.length) startSelect.remove(0)
                                                while (endSelect.options.length) endSelect.remove(0)
                                                //startSelect.innerHtml = '<option value="0"></option>'
                                                //endSelect.innerHtml = '<option value="0"></option>'

                                                let currentYear = parseInt(this.value)
                                                let lastYear = currentYear + 1
                                                if(currentYear === -1) {
                                                    currentYear = startYear
                                                    lastYear = parseInt(end[0])
                                                }

                                                for(let i = currentYear; i <= lastYear; i++) {

                                                    let startMonth = 1
                                                    let endMonth = 12
                                                    if(currentYear === i)
                                                        startMonth = 6

                                                    if(lastYear === i)
                                                        endMonth = parseInt(5)

                                                    for(let m = startMonth; m <= endMonth; m++) {

                                                        let num = m.toString();
                                                        while (num.length < 2) num = "0" + num;

                                                        let opt = document.createElement('option');
                                                        opt.value = i + '-' + num;
                                                        opt.innerHTML = i + '-' + num;

                                                        startSelect.appendChild(opt);

                                                        let opt2 = document.createElement('option');
                                                        opt2.value = i + '-' + num;
                                                        opt2.innerHTML = i + '-' + num;
                                                        if(lastYear === i && m === endMonth)
                                                            opt2.selected = true
                                                        endSelect.appendChild(opt2);

                                                        // console.log(m)
                                                    }

                                                }
                                            })

                                            for(let i = parseInt(start[0]); i <= parseInt(end[0]); i++) {
            
                                                let startMonth = 1
                                                let endMonth = 12
                                                if(parseInt(start[0]) === i)
                                                    startMonth = parseInt(start[1])
            
                                                if(parseInt(end[0]) === i)
                                                    // endMonth = parseInt(end[1])
                                                    endMonth = parseInt(5)

                                                for(let m = startMonth; m <= endMonth; m++) {
            
                                                    let num = m.toString();
                                                    while (num.length < 2) num = "0" + num;
            
                                                    let opt = document.createElement('option');
                                                    opt.value = i + '-' + num;
                                                    opt.innerHTML = i + '-' + num;
            
                                                    startSelect.appendChild(opt);
            
                                                    let opt2 = document.createElement('option');
                                                    opt2.value = i + '-' + num;
                                                    opt2.innerHTML = i + '-' + num;
                                                    endSelect.appendChild(opt2);
            
                                                    // console.log(m)
                                                }
            
                                            }
            
                                            if(jQuery('.custom-datatables-filter').length > 0) {
                                                jQuery('.custom-datatables-filter').off('change', function() {
                                                    table.draw()                
                                                });
                                                jQuery('.custom-datatables-filter').on('change', function() {
                                                    table.draw()                
                                                });
                                            }

                                            startSelect.value = (end[0] - 1) + '-06'
                                            endSelect.value = end[0] + '-05'
                                            jQuery(startSelect).trigger('change')
                                           // console.log(json.sumAnalytic)
                                        },
                                    });

                                /*
                                let addData = document.getElementById('additionalButtons').innerHTML;
                                let tableLengthContainer = document.querySelector('.dataTables_length');
                                tableLengthContainer.innerHTML += addData;
                                */
                                return;
                                // table.draw();
                            }
                        };
                        xhr.open("GET", apiEndpoint + "lists/", true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
                        xhr.send();

                        break;
                    case 'location':
                        if (!jQuery.fn.dataTable.isDataTable('#locationOverviewTable'))
                        table = jQuery('#locationOverviewTable').DataTable({
                            language: datatables_l18,
                            pagingType: "full_numbers",
                            autoWidth: false,
                            pageLength: 50,
                            lengthMenu: [[10, 25, 50, 100], [ 10, 25, 50, 100]],
                            order: [[0, 'desc']],
                            columnDefs: [{ className: "text-right", "targets": [4,5,6,7,8] },
                                        { className: "text-center", "targets": [2,3] },
                                        { orderable: false, "targets": [1,2,3,4,5,6,7,8] },
                                {
                                    'targets': [8],
                                    createdCell: (td, cellData, rowData, row, col) => {
                                        cellData.monthlyPremiumAmount = parseFloat(cellData.monthlyPremiumAmount)

                                        ReactDOM.render(<button type="button" className="btn btn-sm "
                                                                onClick={() => {
                                                                    setCurrentView('accounting');
                                                                    setAccountingSummary(cellData);
                                                                } } >Übersicht</button>, td);

                                    }
                                }
                                        ],
                            processing: true,
                            serverSide: true,
                            ajax: {

                                url: apiEndpoint + "location/" + location.id + "/accounting/" ,
                                dataSrc: "accounting",
                                //dataType: "json",
                                type: "GET",
                                pageLength: 10,
                                crossDomain: true,
                                beforeSend: function (xhr) {
                                    xhr.setRequestHeader("Authorization",
                                        "Bearer " + loginData.token)
                                },

                                data: function ( d ) {
                                    
                                    if(jQuery('.custom-datatables-filter').length > 0) {
                                        jQuery('.custom-datatables-filter').each(function() {
                                            d[jQuery(this).attr('id')] = jQuery(this).val();
                    
                                        });
                                    }

                                    d.dataType = 'datatables';
                                },
                                dataFilter: function(data){

                                    var json = JSON.parse( data );
                                    document.getElementById('premium_amount').innerHTML = json.sumAnalytic.monthly_premium_amount_display
                                    document.getElementById('premium_amount_netto').innerHTML = json.sumAnalytic.additional_insurance_amount_display_netto
                                   // document.getElementById('insurance_amount').innerHTML = json.sumAnalytic.additional_insurance_amount_display

    
                                    return JSON.stringify( json ); // return JSON string
                                },

                                // ReactDOM.findDOMNode(component_name)

                            },
                            fnInitComplete: function(settings, json) {

                                let startSelect = document.getElementById('period_start');
                                let endSelect = document.getElementById('period_end');
                                let yearSelect = document.getElementById('year_select');

                                let start = json.sumAnalytic.min_range.split('-')
                                let end = json.sumAnalytic.max_range.split('-')

                                let startYear = start[0]

                                let currentDate = new Date()
                                start[0] = currentDate.getFullYear() - 1
                                if(currentDate.getMonth >= 5)
                                    end[0] = currentDate.getFullYear() + 1

                                for(let i = parseInt(startYear); i < parseInt(end[0]); i++) {

                                    let opt = document.createElement('option');
                                    opt.value = i.toString();
                                    opt.innerHTML = i.toString();
                                    if(i === parseInt(start[0]))
                                        opt.selected = true

                                    yearSelect.appendChild(opt);
                                }

                                yearSelect.addEventListener('change', function() {

                                    while (startSelect.options.length) startSelect.remove(0)
                                    while (endSelect.options.length) endSelect.remove(0)
                                    //startSelect.innerHtml = '<option value="0"></option>'
                                    //endSelect.innerHtml = '<option value="0"></option>'

                                    let currentYear = parseInt(this.value)
                                    let lastYear = currentYear + 1
                                    if(currentYear === -1) {
                                        currentYear = startYear
                                        lastYear = parseInt(end[0])
                                    }

                                    for(let i = currentYear; i <= lastYear; i++) {

                                        let startMonth = 1
                                        let endMonth = 12
                                        if(currentYear === i)
                                            startMonth = 6

                                        if(lastYear === i)
                                            endMonth = parseInt(5)

                                        for(let m = startMonth; m <= endMonth; m++) {

                                            let num = m.toString();
                                            while (num.length < 2) num = "0" + num;

                                            let opt = document.createElement('option');
                                            opt.value = i + '-' + num;
                                            opt.innerHTML = i + '-' + num;

                                            startSelect.appendChild(opt);

                                            let opt2 = document.createElement('option');
                                            opt2.value = i + '-' + num;
                                            opt2.innerHTML = i + '-' + num;
                                            if(lastYear === i && m === endMonth)
                                                opt2.selected = true
                                            endSelect.appendChild(opt2);

                                            // console.log(m)
                                        }

                                    }
                                })

                                for(let i = parseInt(start[0]); i <= parseInt(end[0]); i++) {

                                    let startMonth = 1
                                    let endMonth = 12
                                    if(parseInt(start[0]) === i)
                                        startMonth = parseInt(start[1])

                                    if(parseInt(end[0]) === i)
                                        // endMonth = parseInt(end[1])
                                        endMonth = parseInt(5)

                                    for(let m = startMonth; m <= endMonth; m++) {

                                        let num = m.toString();
                                        while (num.length < 2) num = "0" + num;

                                        let opt = document.createElement('option');
                                        opt.value = i + '-' + num;
                                        opt.innerHTML = i + '-' + num;

                                        startSelect.appendChild(opt);

                                        let opt2 = document.createElement('option');
                                        opt2.value = i + '-' + num;
                                        opt2.innerHTML = i + '-' + num;
                                        endSelect.appendChild(opt2);

                                        // console.log(m)
                                    }

                                }

                                if(jQuery('.custom-datatables-filter').length > 0) {
                                    jQuery('.custom-datatables-filter').off('change', function() {
                                        table.draw()                
                                    });
                                    jQuery('.custom-datatables-filter').on('change', function() {
                                        table.draw()                
                                    });
                                }

                                startSelect.value = (end[0] - 1) + '-06'
                                endSelect.value = end[0] + '-05'
                                jQuery(startSelect).trigger('change')
                               // console.log(json.sumAnalytic)
                            },
                            
                        });
                        break;
                        xhr.onreadystatechange = function () {
                            /** make token check call */
                            if (this.readyState === 4 && this.status === 200) {

                                let myArr = JSON.parse(this.responseText);
                                setAccountingLocationSummary(myArr);
                                // table.draw();
                                if (!jQuery.fn.dataTable.isDataTable('#locationOverviewTable'))
                                    table = jQuery('#locationOverviewTable').DataTable({
                                        language: datatables_l18,
                                    });
                            }
                        };
                        xhr.open("GET", apiEndpoint + "location/" + location.id + "/accounting", true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
                        xhr.send();

                        break;
                    case 'accounting':

                        table = jQuery('#accountingSummaryTable').DataTable({
                            language: datatables_l18,
                            pagingType: "full_numbers",
                            autoWidth: false,
                            lengthMenu: [[10, 25, 50, 100], [ 10, 25, 50, 100]],
                            columnDefs: [{ className: "text-right", "targets": [3,4,5,6,7] },
                                        { orderable: false, "targets": [1,2,3,4,5,6,7] },
                                {
                                    'targets': [7],
                                    createdCell: (td, cellData, rowData, row, col) => {

                                        ReactDOM.render(<button type="button" className="btn btn-sm "
                                                                onClick={() => {
                                                                    setCurrentView('box');
                                                                    setBox({box: cellData, locationId: location.id});
                                                                } } >Box {cellData}</button>, td);

                                    }
                                }
                                        ],
                            processing: true,
                            serverSide: true,
                            ajax: {

                                // url: apiEndpoint + "location/" + location.id + "/accounting/" + accountingSummary.id,
                                url: apiEndpoint + "location/" + location.id + "/accountings/" + accountingSummary.year + "/" + accountingSummary.month ,
                                dataSrc: "accounting",
                                //dataType: "json",
                                type: "GET",
                                pageLength: 10,
                                crossDomain: true,
                                beforeSend: function (xhr) {
                                    xhr.setRequestHeader("Authorization",
                                        "Bearer " + loginData.token)
                                },

                                data: function ( d ) {
                                    d.dataType = 'datatables';
                                },



                                // ReactDOM.findDOMNode(component_name)

                            },
                        });

                        break;
                    case 'users':
                        xhr.onreadystatechange = function () {
                            /** make token check call */
                            if (this.readyState === 4 && this.status === 200) {

                                let myArr = JSON.parse(this.responseText);
                                setUsers(myArr);
                                // table.draw();
                                if (!jQuery.fn.dataTable.isDataTable('#usersTable'))
                                    table = jQuery('#usersTable').DataTable({
                                        language: datatables_l18,
                                    });
                            }
                        };
                        xhr.open("GET", apiEndpoint + "users", true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
                        xhr.send();
                        break;
                    case 'countries':
                        xhr.onreadystatechange = function () {
                            /** make token check call */
                            if (this.readyState === 4 && this.status === 200) {

                                let myArr = JSON.parse(this.responseText);
                                setCountries(myArr);
                                // table.draw();
                                if (!jQuery.fn.dataTable.isDataTable('#countryTable'))
                                    table = jQuery('#countryTable').DataTable({
                                        language: datatables_l18,
                                    });
                            }
                        };
                        xhr.open("GET", apiEndpoint + "countries", true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
                        xhr.send();
                        break;
                    case 'box':

                        xhr.onreadystatechange = function () {
                            /** make token check call */
                            if (this.readyState === 4 && this.status === 200) {

                                let myArr = JSON.parse(this.responseText);
                                // console.log(myArr.accounting)
                                setBoxAccounting(myArr.accounting);
                                if (!jQuery.fn.dataTable.isDataTable('#boxDataTable'))
                                    table = jQuery('#boxDataTable').DataTable({
                                        language: datatables_l18,
                                    });

                            }
                        };
                        xhr.open("GET", apiEndpoint + "location/" + box.locationId + "/box/"  + box.box, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
                        xhr.send();

                        break;
                    default:
                        break;
                }
            }
        }
    }

    const login = (loginData) => {

        setIsLoading(true)
        setDisplayErrorCallback(false)

        let xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function() {

            // let myArr = JSON.parse(this.responseText);
            // console.log(myArr)
            if (this.readyState === 4 && this.status === 200) {

                let tokenData = JSON.parse(this.responseText);

                if(tokenData.sandBoxUser) {

                    // apiEndpoint = 'https://sandbox.rrvm.de/'
                    apiEndpoint = 'https://trisor123.de/api-sandbox/public/'
                    xmlhttp = new XMLHttpRequest();
                    xmlhttp.onreadystatechange = function() {

                        // let myArr = JSON.parse(this.responseText);
                        // console.log(myArr)
                        if (this.readyState === 4 && this.status === 200) {
                            localStorage.setItem('currentUser', JSON.stringify(tokenData));
                            setLoginState(true);
                            setUserRoles(tokenData.roles);
                            setSandBoxUser(tokenData.sandBoxUser)
                            setIsLoading(false)
                            setContent('main')
                        }
                    }

                    xmlhttp.open("POST", apiEndpoint + "login_check");
                    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                    xmlhttp.send(JSON.stringify(loginData));
                } else {
                    localStorage.setItem('currentUser', JSON.stringify(tokenData));
                    setLoginState(true);
                    setUserRoles(tokenData.roles);
                    setSandBoxUser(tokenData.sandBoxUser)
                    setIsLoading(false)
                    setContent('main')
                }

            } else if(this.status === 401) {
                setDisplayErrorCallback(true)
                setIsLoading(false)
            }
        };
        xmlhttp.open("POST", apiEndpoint + "login_check");
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.send(JSON.stringify(loginData));

    }

    const logout = () => {
        setSandBoxUser(false)
        localStorage.removeItem('currentUser');
        setLoginState(false);

    }

    const saveUser = () => {

        setDisplayErrorCallback(false);
        let login = localStorage.getItem('currentUser');
        let loginData = JSON.parse(login);

        let xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function() {

            if (this.readyState === 4 && this.status === 201) {
                setDisplaySaveCallback(true);
                let jsonData = JSON.parse(this.responseText);

                let index = users.findIndex(obj => obj.id === jsonData.id);
                if(index !== -1)
                    users[index] = jsonData
                else
                    users.push(users)

                setUsers(users)

                setTimeout(function () {setDisplaySaveCallback(false)}, 2000)
            }
            if(this.status === 409) {
                setDisplayErrorCallback(true);
            }
        };
        xmlhttp.open("POST", apiEndpoint + "users");
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
        xmlhttp.send(JSON.stringify(user));

    }

    const saveCountry = () => {

        setDisplayErrorCallback(false);
        let login = localStorage.getItem('currentUser');
        let loginData = JSON.parse(login);

        let xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function() {

            if (this.readyState === 4 && this.status === 201) {
                setDisplaySaveCallback(true);
                let jsonData = JSON.parse(this.responseText);

                let index = countries.findIndex(obj => obj.id === jsonData.id);
                if(index !== -1)
                    countries[index] = jsonData
                else
                    countries.push(country)

                setCountries(countries)

                setTimeout(function () {setDisplaySaveCallback(false)}, 2000)
            }
            if(this.status === 409) {
                setDisplayErrorCallback(true);
            }
        };
        xmlhttp.open("POST", apiEndpoint + "countries");
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.setRequestHeader('Authorization', 'Bearer ' + loginData.token);
        xmlhttp.send(JSON.stringify(country));

    }


    return (
        <>
        <Header isLoggedIn={isLoggedIn} userRoles={userRoles} logOut={logout} setCurrentView={setCurrentView} sandBoxUser={sandBoxUser} />
        <div className="container">
          {!isLoggedIn && !isLoading ? <LoginForm displayErrorCallback={displayErrorCallback} onSubmitForm={login}/> : '' }
            {isLoading ?
          <div id="loaderCanvas" className="twelve columns text-center">
              <div className="loader"></div>

          </div>
                : '' }
            {isLoggedIn && currentView === 'main' ? <Content locations={locations} setCurrentView={setCurrentView} setLocation={setLocation} /> : ''}
          {isLoggedIn && currentView === 'location' ? <LocationData location={location} accountingLocationSummary={accountingLocationSummary} setCurrentView={setCurrentView} setAccountingSummary={setAccountingSummary} /> : ''}
          {isLoggedIn && currentView === 'accounting' ? <AccountingSummaryData accountingSummary={accountingSummary} accountingSummaryItems={accountingSummaryItems} location={location} setCurrentView={setCurrentView} setBox={setBox}  /> : ''}
          {isLoggedIn && currentView === 'box' ? <BoxData boxAccounting={boxAccounting} box={box} location={location} setCurrentView={setCurrentView} setAccountingSummary={setAccountingSummary} accountingSummary={accountingSummary} /> : ''}
          {isLoggedIn  && userRoles.indexOf('ROLE_ADMIN') !== -1 && currentView === 'users' ? <Users users={users} setCurrentView={setCurrentView} setUser={setUser}   /> : ''}
          {isLoggedIn  && userRoles.indexOf('ROLE_ADMIN') !== -1 && currentView === 'user' ? <User user={user} displaySaveCallback={displaySaveCallback} setCurrentView={setCurrentView} onSubmitUserForm={saveUser}  setUser={setUser} displayErrorCallback={displayErrorCallback} /> : ''}{isLoggedIn  && userRoles.indexOf('ROLE_ADMIN') !== -1 && currentView === 'countries' ? <Countries countries={countries} setCurrentView={setCurrentView} setCountry={setCountry}   /> : ''}
          {isLoggedIn  && userRoles.indexOf('ROLE_ADMIN') !== -1 && currentView === 'country' ? <Country country={country} displaySaveCallback={displaySaveCallback} setCurrentView={setCurrentView} onSubmitUserForm={saveCountry}  setCountry={setCountry} displayErrorCallback={displayErrorCallback} /> : ''}
        </div>

        </>
    );
}

export default App;
